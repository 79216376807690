/* In src/App.css or a global stylesheet */
body {
  background-image: url('/public/No_Mans_Sea.jpg'); /* Correct path from the root */
  background-size: cover; /* Ensures the image covers the entire background */
  background-position: center; /* Centers the image */
  background-repeat: no-repeat; /* Prevents repeating the image */
  background-attachment: fixed; /* Keeps the background image fixed during scroll */
  margin: 0; /* Ensures there’s no default margin */
  padding: 0; /* Ensures there’s no default padding */
}

.card {
  background-color: rgba(255, 255, 255, 0.7); /* White background with 90% opacity */
  border: 1px solid #ddd; /* Optional: border color to make it look better */
  border-radius: 0.25rem; /* Optional: rounded corners */
}

.card-header, .card-body, .card-footer {
  background-color: transparent; /* Ensures header, body, and footer have the same transparency */
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: transparent; /* Make header background transparent */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
